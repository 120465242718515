export function TheQuestionSection() {
    return (
        <div>
            {sentences.map((sentence, index) => (
                <div
                    key={index}
                    className="container relative overflow-hidden text-2xl text-white md:text-5xl"
                >
                    <ul className="container inline-block py-12">
                        <li className="inline-block">{sentence}</li>
                    </ul>
                </div>
            ))}
        </div>
    );
}

const sentences = [
    'A busy career?',
    'Late 20s and still single?',
    "Experiencing writer's block?",
    'Struggling to get replies from matches?',
    'Bored of repetitive, shallow conversations?',
];
