import type { MetaFunction } from '@remix-run/cloudflare';
import { ExtensionTeaser } from '~/components/Index/ExtensionTeaser';
import { PoweredByBestAI } from '~/components/Index/PoweredByBestAI';
import { TheFeatureSection } from '~/components/Index/TheFeatureSection';
import { TheHeroSection } from '~/components/Index/TheHeroSection';
import { Pricing } from '~/components/Index/Pricing';
import { TheQuestionSection } from '~/components/Index/TheQuestionSection';
import { TheWhatIfSection } from '~/components/Index/TheWhatIfSection';
import { Statistics } from '~/components/Statistics';
import { WooshEffect } from '~/components/WooshEffect';
import { Blur } from '~/components/Blur';
import { PrefetchPageLinks } from '@remix-run/react';
import { FAQSection } from '~/components/Index/FAQSection';

export const meta: MetaFunction = () => {
    return [
        { title: 'Elevate your conversations on Dating Apps' },
        {
            name: 'description',
            content:
                'Unlock the secrets to fun chats and build meaningful connection with your ideal partner. Complete suite of tools and AI to help you get more out of your matches and dates.',
        },
    ];
};

export default function Index() {
    return (
        <>
            <WooshEffect />
            <Blur />
            <div className="relative">
                <TheHeroSection />
                <TheQuestionSection />
                <TheWhatIfSection />
                <TheFeatureSection />
                <PoweredByBestAI />
                <ExtensionTeaser />
                <Pricing />
                <FAQSection />
                <Statistics />
            </div>
            <PrefetchPageLinks page="/extension" />
        </>
    );
}
