import './TheWhatIfSection.css';

export function TheWhatIfSection() {
    return (
        <section className="container">
            <div className="relative flex min-h-[80vh] items-center justify-center">
                <div className="animate-blur-box absolute h-64 w-full bg-blue-400 filter"></div>
                <div className="group absolute flex h-64 w-full items-center justify-center rounded-md bg-black text-center text-xl text-white">
                    <p className="text-center text-5xl font-semibold text-white">
                        What if this{' '}
                        <span className="text-green-400 group-hover:text-green-600 transition-colors duration-300">
                            could get better...
                        </span>
                    </p>
                </div>
            </div>
        </section>
    );
}
