export function TheAppsSection() {
    return (
        <section className="container py-12 ">
            <p className="mb-4 text-center uppercase text-sm ">
                Compatible with
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-8">
                <p className="text-3xl font-extrabold uppercase tracking-widest gradient-heroic-text">
                    Tinder
                </p>
                <p className="text-3xl font-extrabold uppercase tracking-widest gradient-heroic-text">
                    Bumble
                </p>
            </div>
        </section>
    );
}
