import { Headline } from '~/components/General/Headline';

const questions = [
    {
        question: 'Is this any good?',
        answer: 'Yes.',
    },
    {
        question: 'Is this a chatbot? Can it text for me?',
        answer: 'No. Dating Studio is not and never will be a chatbot. We believe in authenticity and transparency. We provide tools to help you text better, but you are always in control of what you send.',
    },
    {
        question: 'How does Dating Studio work?',
        answer: 'Dating Studio is a library and a browser extension that helps you write better messages on dating apps. We provide message suggestions based on your match’s profile and our AI analysis. The library lets you reflect upon your past messages and learn from them.',
    },
    {
        question: 'How much does Dating Studio cost?',
        answer: 'It depends. We offer a premium subscription that unlocks additional features and top AI models, but the core library functionality is free.',
    },
    {
        question: 'Is the extension available on mobile?',
        answer: 'The extension is currently available only for Chrome and Chromium-based browsers such as Opera or Microsoft Edge. The platform can be accessed from any modern browser with an internet connection.',
    },
    {
        question: 'Is my data safe and secure?',
        answer: 'Yes. We take privacy and security very seriously. We encrypt data at rest and in transit. We only analyze your messages to provide better suggestions and will never share or sell your data to third parties.',
    },
];

export function FAQSection() {
    return (
        <section className="container min-h-[60vh] my-12 items-center">
            <Headline title="Frequently asked" titleColored="questions" />

            <div className="join join-vertical w-full">
                {questions.map((item, index) => (
                    <div
                        key={index}
                        className="collapse collapse-arrow join-item border border-base-300"
                    >
                        <input
                            type="radio"
                            name="my-accordion-4"
                            defaultChecked={index === 0}
                        />
                        <div className="collapse-title text-xl font-medium">
                            {item.question}
                        </div>
                        <div className="collapse-content">
                            <p>{item.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
