import { Headline } from '~/components/General/Headline';
import { Anthropic } from '~/components/Icon/Anthropic';
import { Meta } from '~/components/Icon/Meta';
import { OpenAI } from '~/components/Icon/OpenAI';

export function PoweredByBestAI() {
    return (
        <section className="container min-h-[50vh] flex flex-col h-full py-12 text-white">
            <div className="w-full flex flex-col flex-1">
                <Headline
                    title="Powered by best"
                    titleColored="AI in the industry"
                >
                    Dating Studio gives you access to the best models out there.
                </Headline>

                <div className="my-16 flex flex-col items-center justify-between md:flex-row gap-24 md:gap-[unset] flex-1">
                    <OpenAI
                        className="h-8 opacity-70 hover:opacity-100"
                        aria-label="GPT 4 by OpenAI"
                    />
                    <Anthropic
                        className="h-8 opacity-70 hover:opacity-100 fill-gray-100"
                        aria-label="Anthropic's Claude"
                    />
                    <Meta
                        className="h-8 opacity-70 hover:opacity-100 fill-gray-100"
                        aria-label="Llama 2 by Facebook"
                    />
                </div>
            </div>

            <small className="text-base-content self-end text-sm max-w-xl">
                *We are not affiliated nor endorsed by any of the companies
                above. Their terms and conditions apply and must be respected
                when using their services.
            </small>
        </section>
    );
}
