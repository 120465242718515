import { Link } from '@remix-run/react';
import chromeStoreScreen from '~/assets/screens/chrome-store.png';

export function ExtensionTeaser() {
    return (
        <section className="container pb-20 text-white">
            <div className="items-center gap-8 px-4 py-8 sm:py-16 md:grid md:grid-cols-2 lg:px-6 xl:gap-16">
                <article>
                    <h2 className="mb-4 text-4xl font-bold leading-tight text-white">
                        Intrigued? Discover the key product.
                    </h2>
                    <p className="mb-6 max-w-xl font-light text-gray-400">
                        The key product is the browser extension. It's free and
                        provided as-is while in early public access. Those, who
                        join now, will get huge perks upon release.
                    </p>
                    <Link to="/extension">
                        <button className="inline-block w-full rounded-lg bg-blue-600 px-8 py-3 text-lg font-semibold leading-none text-white hover:bg-blue-700 sm:w-auto sm:px-6 sm:py-4">
                            Learn More
                        </button>
                    </Link>
                </article>

                <img
                    className="w-full rounded-xl opacity-90 outline -outline-offset-8 outline-zinc-900 dark:opacity-70"
                    src={chromeStoreScreen}
                    alt="chat-gpt inside bumble"
                />
            </div>
        </section>
    );
}
