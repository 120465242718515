export function Blur() {
    return (
        <div className="pointer-events-none absolute inset-0 h-[2400px] w-full overflow-hidden pb-64">
            <img
                style={{
                    position: 'absolute',
                    width: '700px',
                    height: '700px',
                    right: '10%',
                    top: '16px',
                    animationDelay: '1s',
                    animationDuration: '4.25s',
                }}
                src="/blur.svg"
                className="animate-pulse-slow"
            />
            <img
                style={{
                    position: 'absolute',
                    width: '700px',
                    height: '700px',
                    right: '25%',
                    top: '520px',
                }}
                src="/blur.svg"
                className="animate-pulse-slow"
            />
            <img
                style={{
                    position: 'absolute',
                    width: '400px',
                    height: '400px',
                    left: '35%',
                    top: '1156px',
                    opacity: 0.6 /* Corrected from '0.6s' to '0.6' as it appears to be a typo */,
                }}
                src="/blur.svg"
                className="animate-pulse-slow"
            />
        </div>
    );
}
