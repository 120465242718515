import { Headline } from '~/components/General/Headline';
import { NuxtCard } from '~/components/General/NuxtCard';

export function TheFeatureSection() {
    const cards = [
        {
            title: 'Modern Library',
            description:
                'Rediscover your past conversations with lifetime archive and rapid search capabilities. Dive deep into emotions, intentions, and outcomes.',
        },
        {
            title: 'Seamless Import',
            description:
                'Leverage our advanced OCR technology to seamlessly import your chats from screenshots. Centralize your conversations.',
        },
        {
            title: 'ChadAI',
            description:
                'Empower your conversations with ChadAI. Your personal intelligence, committed to your dating success.',
        },
        {
            title: 'Insightful Learnings',
            description:
                'Like a personal coach that never sleeps, discover strengths and room for improvement with tailored insights into your dating style and preferences.',
        },
        {
            title: 'AI Feedback',
            description:
                'Augment your communication skills with our AI-guided feedback mechanism. Learn the art of captivating conversations.',
        },
        {
            title: 'Chrome Extension',
            description:
                'Boost your dating experience with our Chrome Extension. Access our powerful tools directly from your browser.',
        },
    ];

    return (
        <section className="container text-white">
            <Headline
                title="Discover a new"
                titleColored="Dating Experience"
                children="Our suite of tools are designed to empower your dating journey, helping you foster genuine connections and meaningful relationships."
            />
            <div className="grid grid-cols-1 gap-8 pt-16 sm:grid-cols-2 lg:grid-cols-3">
                {cards.map((card, index) => (
                    <NuxtCard key={index}>
                        <h2 className="mb-4 text-xl font-semibold">
                            {card.title}
                        </h2>
                        <p>{card.description}</p>
                    </NuxtCard>
                ))}
            </div>
        </section>
    );
}
