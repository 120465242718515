import { TheAppsSection } from '~/components/Index/TheAppsSection';

export function TheHeroSection() {
    function scrollTo() {
        // Calculate the height of the current element and scroll past it
        const elementHeight = window.innerHeight * 0.95;
        window.scrollBy({
            top: elementHeight,
            behavior: 'smooth',
        });
    }

    return (
        <section className="container relative mx-auto flex min-h-screen flex-col items-center justify-center">
            <div className="mb-16 p-4">
                <h1 className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-400 to-80% text-5xl font-bold sm:text-6xl md:text-8xl">
                    Elevate{' '}
                    <span className="underline decoration-slate-300">your</span>{' '}
                    conversations on dating apps
                </h1>
                <p className="mt-4 text-xl text-white md:text-4xl">
                    Unlock the secrets to fun chats and build meaningful
                    connection with your ideal partner.
                </p>
            </div>

            <TheAppsSection />

            <div
                onClick={scrollTo}
                className="absolute bottom-8 hidden flex-col items-center justify-center gap-4 text-gray-300 hover:cursor-pointer sm:flex"
            >
                <p>Scroll down to learn more</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-12 w-12 animate-bounce"
                >
                    <path
                        fillRule="evenodd"
                        d="M7.293 7.293a1 1 0 011.414 0L10 8.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
        </section>
    );
}
